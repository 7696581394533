<template>
  <b-modal ref="ubication" id="bv-modal-example" hide-footer>
    <template #modal-title
      >{{ form.id === null ? "Agregar nueva marca" : "Editar marca" }}
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <iq-card>
          <template v-slot:body>
            <div class="new-user-info">
              <b-row class="justify-content-md-center">
                <b-form-group>
                  <div class="add-img-user profile-img-edit text-center">
                    <b-img
                      class="height-180 width-180"
                      fluid
                      center
                      thumbnail
                      :src="preview"
                      alt="image"
                    />
                    <input type="hidden" />
                    <div class="p-image">
                      <b-button
                        variant="none"
                        class="upload-button iq-bg-primary position-relative"
                      >
                        <input
                          type="file"
                          @change="previewImage"
                          class="h-100 position-absolute btn-block"
                          accept="image/*"
                          style="opacity: 0"
                        />
                        CARGAR IMAGEN
                      </b-button>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  class="col-md-12"
                  label="Nombres"
                  label-for="marca"
                >
                  <ValidationProvider
                    name="Nombre"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.name"
                      type="text"
                      placeholder="Nombre de la Marca"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                    </b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
              <hr />
              <b-button variant="primary" block type="submit">Guardar</b-button>
            </div>
          </template>
        </iq-card>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions } from "vuex";
export default {
  mounted() {
    core.index();
  },
  data() {
    return {
        form: {
        id: null,
        name: null,
        image: null,
      },
      preview: require("@/assets/images/default.png"),
    };
  },
  methods: {
    ...mapActions({
      createOrUpdate: "brand/storeBrand",
    }),
    showModal(item) {
      if (item) {
        this.form = {
          id: item.id,
          name: item.name,
        };

        this.preview = item.image == "" ? require("@/assets/images/default.png") : item.image
      } else {
        this.form = {
          id: null,
          name: null,
          image: null,
        };
        this.preview = require("@/assets/images/default.png")
      }
      this.$bvModal.show("bv-modal-example");
    },
    async onSubmit() {
      try {
        const res = await this.createOrUpdate(this.form);
        this.$bvModal.hide("bv-modal-example");
        this.form = {
          id: null,
          name: null,
          image: null,
        };
        this.preview = require("@/assets/images/default.png");
        core.showSnackbar("success", res);
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    previewImage: function (event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        this.form.image = input.files[0];
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
  }
};
</script>